@import '../../../../../../../../core/scss/mixins/media';

.content {
    display: flex;
    margin-bottom: 100px;
    overflow-x: hidden;

    .toc {
        // min-width: 280px;
        min-width: 250px;
        margin-left: 50px;
    }

    .tocItem {
        display: flex;
    }
}

@include md-desktop {
    // .text {
    //     margin: 0 20px;
    // }

    .toc {
        // margin-top: 40px !important;
        display: none;
    }
}