@import 'src/core/scss/vars';
@import 'src/core/scss/mixins/media';

.header {
    display: flex;
    gap: 50px;

    .image {
        height: 160px;
        border-radius: 10px;
    }

    .text {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .description {
            font-family: $f-medium;
            font-size: $fs-24;
            margin-top: 10px;
        }

        .link {
            margin-top: 5px;
        }
    }
}

@include md-desktop {
    .header {
        flex-direction: column;
        align-items: center;
        gap: 30px;

        .image {
            width: 160px;
        }

        .title {
            font-size: 48px;
        }

        .title, .description, .link {
            text-align: center;
        }
        .link {
            margin: 0 auto;
        }
    }
}