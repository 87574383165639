@import 'src/core/scss/vars';

.block {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    padding: 24px 16px !important;

    .top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .tag {
            margin-right: auto;
        }
    }

    .title {
        margin: 20px 10px 0;
    }

    .date {
        color: $gray-1;
        margin-right: 10px;
    }
    .bottomDate {
        margin-top: 20px;
        margin-left: 10px;
    }
}