@import '../../core/scss/vars';
@import '../../core/scss/mixins/media';

.content {
    width: 100%;
    margin: 0 auto 134px;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .top {
        margin-top: auto;
        display: flex;

        .logo {
            max-width: 400px;
            max-height: 400px;
            width: 100%;
            height: 100%;
        }

        .right {
            max-width: 870px;
            width: 100%;
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .title {
                text-align: center;
            }

            .description {
                margin-top: 30px;
                text-align: center;
                //color: $blue-2;
                color: $blue;
            }

            .button {
                margin-top: 60px;
            }
        }
    }

    .bottom {
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
        margin: 0 auto;

        .img {
            width: 160px;
        }
        .brandHint {
            text-align: center;
        }
    }
}

@include lg-desktop {
    .content {
        .logo {
            max-width: 300px;
            max-height: 300px;
        }
    }
}

@include md-desktop {
    .content {
        flex-direction: column;

        .logo {
            //display: none;
            position: absolute;
            z-index: -1;
            opacity: 0.1;
        }
    }

    .bottom {
        flex-direction: column;
        margin: 0 20px;
    }
}