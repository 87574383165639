@import "../../../../scss/vars";

.link {
  display: flex;
  padding: 5px;
  border-radius: 100px;
  transition: all .2s;

  .logo {
    width: 100px;
    transition: all .2s;
  }

  .title {
    text-transform: uppercase;
    color: $white;
    font-family: $f-logo;
  }

  &:hover {
    background-color: $white;
  }
}