@import "../../../../core/scss/mixins/media";

.wrapper {
  display: flex;
  gap: 50px;
  width: 100%;

  .toc {
    margin-top: 100px;
  }
}

@include lg-desktop {
  .wrapper {
    .toc {
      margin-top: 0;
    }
  }
}