
.content {
    display: flex;
    gap: 10px;
    align-items: center;
    
    .image {
        height: 50px;
        width: 50px;
        border-radius: 40px;
        object-fit: cover;
    }
}