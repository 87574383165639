@import '../../../scss/vars';

.link {
    display: flex;
    width: fit-content;

    .content {
        transition: all .2s;
        padding: 20px 35px;
        background-color: $blue;
        border-radius: 10px;

        &:hover {
            background-color: $white;
            color: $black;
        }
    }
}