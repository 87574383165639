@import "src/core/scss/vars";
@import "src/core/scss/mixins/media";


.content h2, .h2 {
    font-family: $f-book;
    font-size: $fs-48;
    padding-top: 28px;
    margin-bottom: 32px;
    border-top: 1px solid $blue-dark-3;

    &:not(:first-child) {
        margin-top: 45px;
    }
}

@include sm-tablets {
    .content h2, .h2 {
        font-size: $fs-36;
        margin-bottom: 16px;
    }
}

@include sm-mobile {
    .content h2, .h2 {
        font-size: $fs-32;
        &:not(:first-child) {
            margin-top: 45px;
        }
    }
}