@import "../../../../../../../../core/scss/mixins/media";

.header {
    width: 100%;
    display: flex;
    margin-top: 60px;
    gap: 50px;

    .info {
        min-width: 280px;
        margin: auto;
    }
}


@include lg-desktop {
    .header {
        flex-direction: column;
        margin-top: 0;
        gap: 30px;

        .info {
            margin: 0;
            display: flex;
            gap: 10px;
        }
    }
}