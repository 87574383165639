.block {
	position: relative;

	.content {
		position: absolute;
		z-index: -1;
		width: auto;
		max-height: 0;
		transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
		left: -20px;
		opacity: 0;
		top: 40px;
		overflow: hidden;
	}

	.showContent {
		opacity: 1;
		z-index: 1;
		max-height: 1000px !important;
		width: auto;
	}
}