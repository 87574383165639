@import '../../../../../../../../../../../../../../../../../../../core/scss/vars';

.block {
	display: flex;
	align-items: center;
	gap: 6px;

	.image {
		margin-left: 10px;
	}
}