@import "src/core/scss/vars";
@import "src/core/scss/mixins/media";

.button {
  padding: 10px 10px 6px;
  border-radius: 0 10px 10px 0;
  background-color: $blue;
  transition: all .2s;

  .image {
    width: 24px;
    height: 24px;
  }
}

/* Small mobile devices | 480px or less */
@include sm-mobile {
  .button {
    padding: 8px 8px 4px;
    .image {
      width: 20px !important;
      height: 20px !important;
    }
  }
}