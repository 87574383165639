@import 'src/core/scss/mixins/media';


.page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 100px;
    
    .header {
        margin-top: 100px;
    }

    .list {
        margin-top: 85px;
    }
}

@include md-desktop {
    .header {
        margin-top: 40px !important;
    }
}