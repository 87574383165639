@import "src/core/scss/vars";

.block {
  width: 100%;
  padding: 20px;
  background-color: #0F0F10;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;
    margin: 0 auto;
    // max-width: 880px;
  }

  .text {
    color: $gray-1;
    text-align: center;
    transition: all .2s;
  }

  .dev {
    display: flex;
    //align-items: center;
    justify-content: center;
    margin: 0 auto;
    //background-color: $white;
    //border-radius: 20px;
    //padding: 10px 20px;
    //border: 2px solid $white;
    //transition: all .2s;

    .text {
      display: flex;
      //color: $black;
      color: $gray-1;
    }
    &:hover {
      border-color: $white;
      //background-color: $black;

      .text {
        color: $white;
      }
    }
  }
}