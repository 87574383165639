@import 'src/core/scss/vars';
@import 'src/core/scss/mixins/media';

.text {
    padding: 15px 20px;
    background-color: $blue;
    border-radius: 10px 0 0 10px;
    transition: all .2s;

    &:hover {
        background-color: $white;
        color: $black;
    }
}

/* Small mobile devices | 480px or less */
@include sm-mobile {
    .text {
        padding: 10px 15px;
        font-size: $fs-16 !important;
    }
}