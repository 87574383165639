@import "src/core/scss/vars";

.button {
  transition: all .2s;
  padding: 25px 40px;
  background-color: $blue;
  border-radius: 10px;

  .title {
    transition: all .2s;
  }

  &:hover {
    background-color: $white;

    .title {
      color: $black;
    }
  }
}