@import '../../../../../../../core/scss/vars';

.footer {
  .main {
    padding: 75px 20px 60px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $black;

    .logo {
      width: 176px;
      height: 176px;
    }

    .list {
      margin-top: 30px;
    }

    .nonVisible {
      display: none;
      //display: flex;
      //flex-direction: column;
    }

    .social {
      margin-top: 40px;
      margin-right: 40px;
    }
  }
}