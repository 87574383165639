@import "../../../../../../../../../../../../../core/scss/vars";

.item {
  color: $gray-1;
  transition: all .2s;

  &:hover {
    color: $white;
  }
}

.active {
  color: $blue;
}