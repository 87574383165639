@import "src/core/scss/vars";

.button {
  padding: 10px;
  backdrop-filter: blur(16px);
  background-color: rgba($blue-dark-1, 0.2);
  //border: 2px solid rgba($blue-dark-1, 0.2);
  border-radius: 10px;
  transition: all .2s;

  &:hover {
    background-color: rgba($white, 0.5);
    //border-color: $blue;
  }

  .image {
    transition: all .2s;
    width: 30px;
    height: 30px;
    display: -webkit-box;
  }
}