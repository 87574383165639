@import "src/core/scss/vars";
.customCheckbox{
  /* для элемента input c type="checkbox" */
  &>input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    /* стили при наведении курсора на checkbox */
    &:not(:disabled):not(:checked)+span:hover::before {
      border-color: #b3d7ff;
    }

    /* стили для активного чекбокса (при нажатии на него) */
    &:not(:disabled):active+span::before {
      background-color: #b3d7ff;
      border-color: #b3d7ff;
    }

    /* стили для чекбокса, находящегося в фокусе */
    &:focus+span::before {
      box-shadow: 0 0 0 0.2rem rgba($green, 0.25);
    }

    /* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
    &:focus:not(:checked)+span::before {
      border-color: #80bdff;
    }

    /* стили для чекбокса, находящегося в состоянии checked */
    &:checked+span::before {
      border-color: $green;
      background-color: $green;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }

    /* стили для чекбокса, находящегося в состоянии disabled */
    &:disabled+span::before {
      background-color: #e9ecef;
    }

  }

  /* для элемента label, связанного с .customCheckbox */
  &>span {
    display: inline-flex;
    user-select: none;

    /* создание в label псевдоэлемента before со следующими стилями */
    &::before {
      content: '';
      display: inline-block;
      flex-shrink: 0;
      flex-grow: 0;
      border: 1px solid #adb5bd;
      border-radius: 0.25em;
      margin-right: 0.5em;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50% 50%;
    }
  }
}

span::before {
  background-color: $white;
  width: 22px;
  height: 22px;
}