@import "src/core/scss/vars";
@import "src/core/scss/mixins/media";


.content a, .link {
  font-family: $f-book;
  font-size: $fs-20;
  color: $white;
  border-bottom: 1px dashed $white;
  transition: all .3s;
  padding-bottom: 2px;
  width: fit-content;
  cursor: pointer;

  &:hover {
    color: $blue;
    border-color: $blue;
    border-bottom: 1px solid;
  }
}

@include sm-mobile {
  .content a, .link {
    font-size: $fs-20 !important;
  }
}