@import "src/core/scss/mixins/media";

.block {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 100px;

    .title {
        text-align: center;
    }
    
    .list {
        margin-top: 85px;
    }
}

/* Small mobile devices | 480px or less */
@include sm-mobile {
    .title {
        font-size: 50px !important;
    }
}