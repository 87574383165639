@import '../../../../../../../core/scss/mixins/media';
@import '../../../../../../../core/scss/vars';

.header {
	width: 100%;
	display: flex;
	justify-content: center;
	height: 131px;

	.wrapper {
		position: fixed;
		z-index: 100;
		backdrop-filter: blur(16px);
		background-color: rgba($blue-dark-1, 0.2);
		padding: 10px 0;
		width: 100%;
		border-bottom: 1px solid $blue-dark-3;

		.content {
			max-width: $elg-desktop-width;
			display: flex;
			width: 100%;
			justify-content: space-between;
			align-items: center;
			margin: 0 auto;
			padding: 0 10px;

			.logo {
				margin-left: 20px;
			}
		}
	}
}

/* Large devices | 1024px or less */
@include md-desktop {
	.wrapper {
		padding: 10px 0 !important;

		.content {
			justify-content: center !important;
		}
	}
}