@import "src/core/scss/vars";
@import "src/core/scss/mixins/media";

.brand {
  display: flex;
  align-items: center;
  gap: 40px;

  padding: 20px 50px 20px 30px;
  border-radius: 20px;
  background-color: $blue-dark-2;
  transition: all .2s;

  &:hover {
    background-color: $blue-dark-3;
  }

  .image {
    width: 200px;
  }

  .text {
    padding: 10px 0;

    .description {
      font-family: $f-book;
      font-size: $fs-24;
      color: $white;
      margin-top: 15px;
    }
  }
}

@include md-desktop {
  .brand {
    flex-direction: column;
    gap: 20px;

    .image {
      width: 400px;
    }

    .text {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        text-align: center;
      }
      .description {
        text-align: center;
      }
    }
  }
}

@include sm-tablets {
  .brand {
    padding: 10px;

    .image {
      width: 300px;
    }
    .text {
      .description {
        font-size: $fs-20;
        margin-top: 10px;
      }
    }
  }
}

@include sm-mobile {
  .brand {
    .image {
      width: 220px;
    }
  }
}