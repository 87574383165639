@import '../../../../core/scss/mixins/media';

.wrapper {
    position: relative;
    display: flex;
    margin: 20px auto 0 auto;
    justify-content: center;

    max-width: $elg-desktop-width;
    width: 100%;
    padding: 0 10px;

    //min-height: 80vh;
    min-height: calc(100vh - 151px);
}

.bug {
    position: fixed;
    z-index: 999;
    left: -278px;
    right: auto;
    bottom: 55px;
}

.application {
    position: fixed;
    z-index: 999;
    left: auto;
    right: 0;
    bottom: 50px;
}

@include sm-tablets {
    .bug {
        left: -300px;
        top: 0;
    }
}