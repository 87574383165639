@import 'src/core/scss/vars';

.list {
	display: flex;
	flex-direction: column;
	z-index: 100;
	background-color: rgba($blue-dark-1, 0.95);
	padding: 15px 20px;
	gap: 20px;
	height: 100vh;
	border-right: 1px solid $blue-dark-3;
}