@import '../../../../scss/vars';

.input {
    outline: none;
    font-family: $f-book;
    border-radius: 10px;
    background-color: $white;
    color: $black;
    border: 2px solid $white;
    transition: all .2s;

    &:focus {
        border-color: $orange;
        box-shadow: 0 0 0 0.2rem rgba($orange, 0.25);
    }
}