@import '../../../../../../../../../../../../../../../../../../core/scss/vars';

.block {
	display: flex;
	padding: 10px 15px 8px 15px;
	border-radius: 10px;
	background: $blue-dark-2;
	transition: all .2s;
	border: 2px solid $blue-dark-2;

	&:hover {
		background: $blue-dark-3;
	}

	.title {
		text-transform: uppercase;
		text-align: center;
		cursor: pointer;
		transition: all .2s;
		white-space: nowrap;
	}
}

.active {
	border-color: $blue;

	&:hover {
		background: $white;
		border-color: $white;

		.title {
			color: $blue-dark-2;
		}
	}
}