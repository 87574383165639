@import 'src/core/scss/vars';
@import 'src/core/scss/mixins/media';

.block {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    min-height: 510px;

    .image {
        border-radius: 20px;
        object-fit: cover;
    }

    .content {
        margin-top: 20px;
        height: 100%;
        display: flex;
        flex-direction: column;

        .tag {
            margin-right: auto;
        }

        .text {
            margin-top: 10px;

            .description {
                color: $white;
                margin-top: 7px;
                font-family: $f-book;
                font-size: $fs-20;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }

        .date {
            color: $gray-1;
            margin-top: auto;
            padding-top: 10px;
        }
    }
}

@include sm-tablets {
    .date {
        font-size: 15px !important;
        //margin-top: 10px !important;
    }
}

@include sm-mobile {
    .block {
        .image {
            max-height: 200px;
        }
    }
}