@import 'src/core/scss/vars';
@import 'src/core/scss/mixins/media';

.content {
    // max-width: 750px;

    h2 {
        font-family: $f-book;
        font-size: $fs-48;
        padding-top: 28px;
        margin-bottom: 32px;
        border-top: 1px solid $blue-dark-3;

        &:not(:first-child) {
            margin-top: 65px;
        }
    }
    h3 {
        font-family: $f-book;
        font-size: $fs-40;
        margin-top: 10px;
        margin-bottom: 22px;
    }
    p, a, li, ol  {
        font-family: $f-book;
        font-size: $fs-24 !important;
        line-height: 35px;
        margin-bottom: 18px;
        color: $gray-2;
    }

    strong {
        font-family: $f-bold;
        font-weight: 100;
    }

    ol {
        padding-left: 32px;
        margin-top: 12px;
    }

    img {
        width: 100% !important;
        height: 100% !important;
        border-radius: 20px;
        object-fit: cover;
        margin-top: 24px;
    }

    iframe {
        width: 100% !important;
        height: 480px;
        margin-top: 24px;
        margin-bottom: 10px;
        border-radius: 20px;
        border: 2px solid $blue;
    }
}

@include sm-tablets {
    .content {
        h3 {
            font-size: $fs-32;
        }
        iframe {
            height: 320px;
        }
    }
}

@include sm-mobile {
    .content {
        p, a, ol, li {
            font-size: $fs-20 !important;
            line-height: 30px;
        }
        img, .image {
            border-radius: 10px;
        }
    }
}

@include esm-mobile {
    .content {
        h3 {
            font-size: $fs-24;
        }
    }
}