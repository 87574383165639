.block {
  max-width: 280px;
  width: 100%;
  margin-bottom: 50px;

  .list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: sticky;
    top: 150px;
    bottom: 50px;
  }
}