@import '../../../../../../../../../../../core/scss/mixins/media';
@import '../../../../../../../../../../../core/scss/vars';

.text {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    .description {
        font-family: $f-book;
        font-size: $fs-32;
    }
    a {
        font-size: $fs-32 !important;
    }
}

@include md-desktop {

    .text {
        .description {
            font-size: $fs-24;
        }
    }
}