@import '../../../../../../../../../../../core/scss/vars';

.title {
	text-transform: uppercase;
}

.line {
	margin-top: 10px;
	height: 2px;
	width: 100%;
	background-color: $blue-dark-3;
}

.content {
	margin-top: 13px;
	display: flex;
	flex-direction: column;
	gap: 10px;

	.item {
		transition: all .2s;
		width: auto;

		&:hover {
			color: $blue !important;
		}
	}
}