@import '../../../../../../../../../../../../../../core/scss/vars';


.item {
    position: relative;
    overflow: hidden;
    display: flex;
    cursor: pointer;
    transition: all .2s;

    .block {
        width: 5px;
        height: 24px;
        border-radius: 10px;
        position: absolute;
    }

    &.active {
        .block {
            background-color: $blue;
        }

        .title {
            margin-left: 20px;
            color: $white;
        }
    }
    .title {
        transition: all .2s;
        color: $gray-1;
        font-family: $f-medium;
        font-size: $fs-20;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;

        &:hover {
            color: $white;
        }
    }
}