@import 'src/core/scss/vars';
@import 'src/core/scss/mixins/media';

.block {
    background-color: $blue-dark-2;
    border-radius: 20px;
    padding: 20px;
    border: 2px solid $blue-dark-2;
    transition: all .2s;

    &:hover {
        border-color: $white;
    }


    .image {
        width: 100%;
        border-radius: 20px;
    }

    .content {
        margin-right: 60px;
        display: flex;
        flex-direction: column;
    }
}

//
@include sm-mobile {
    .block {
        //padding: 20px 12px;
        //border-radius: 18px;
    }
}