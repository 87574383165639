@import '../../../../../core/scss/vars';
@import '../../../../../core/scss/mixins/media';

.background {
    position: absolute;
    z-index: -1;
    // background: linear-gradient(180deg, $blue-dark-1 0%, #000 100%, #000 100%);

    .redBall {
        margin-left: 857px;
    }

    .purpleBall {
        position: absolute;
        top: 498px;
        right: 314px;
    }

}

@include md-desktop {
    .background {
        .redBall, .purpleBall {
            display: none;
        }
    }
}