@import 'src/core/scss/vars';
@import 'src/core/scss/mixins/media';

.block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 40px 40px 35px;
    background-color: $blue-dark-2;
    border-radius: 20px;
    transition: all 0.2s;

    overflow: hidden;
    max-height: 370px;
    max-width: 420px;
    width: 100%;

    .imageWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;

        .image {
            filter: grayscale(100%); 
            transition: filter 0.2s;
            max-height: 100%;
            // min-width: 100%;
            object-fit: contain;
            border-radius: 10px;
        }
    }

    .title {
        margin-top: 10px;
        text-align: center;
    }

    &:hover {
        background-color: $blue-dark-3;

        .image {
            filter: grayscale(0%);
        }
    }
}

/* Small mobile devices | 768px or less */
@include sm-tablets {
    .block {
        padding: 20px 20px 15px;
        max-height: none;

        .image {
            width: 100%;
        }
    }
}

/* Small mobile devices | 480px or less */
@include sm-mobile {
    .title {
        font-size: 20px !important;
    }
}